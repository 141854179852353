import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import { Facebook, Twitter } from 'react-sharingbuttons';
import './news-post.scss';

export const NewsPostTemplate = ({
  content,
  contentComponent,
  title,
  date,
  helmet,
  url,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <div className="content">
      {helmet || ''}
      <div className="content__inner">
        <section className="article">
          <div className="section-heading-blue">
            <h2>NEWS</h2>
          </div>
          <div className="article-content">
            <div className="article-title">
              <span>{date}</span>
              <div className="heading-blue">
                <h3>{title}</h3>
              </div>
            </div>
            <div className="article-share-btn">
              <Twitter
                text="ツイート"
                url={`https://stings-website-test.netlify.app${url}`}
                shareText={title}
              />
              <Facebook
                text="シェア"
                url={`https://stings-website-test.netlify.app${url}`}
                shareText={title}
              />
            </div>
            <PostContent content={content} />
          </div>
        </section>
      </div>
    </div>
  );
};

NewsPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  url: PropTypes.string,
  helmet: PropTypes.object,
};

const NewsPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <NewsPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        date={post.frontmatter.date}
        url={post.fields.slug}
        helmet={
          <Helmet titleTemplate="%s | News">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <meta property="og:title" content={`${post.frontmatter.title}`} />
            <meta
              property="og:description"
              content={`${post.frontmatter.description}`}
            />
            <meta
              name="twitter:title"
              content={`${post.frontmatter.title}`}
            ></meta>
            <meta
              name="twitter:description"
              content={`${post.frontmatter.description}`}
            ></meta>
          </Helmet>
        }
      />
    </Layout>
  );
};

NewsPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default NewsPost;

export const pageQuery = graphql`
  query NewsPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      fields {
        slug
      }
      html
      frontmatter {
        date(formatString: "YYYY.MM.DD")
        title
        description
      }
    }
  }
`;
